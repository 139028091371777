import { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import SidebarUser from './SidebarUser';
import { isLg, unauthorizedErrorHandler } from '../../utils/Helper';
import AutoSuggest from '../forms/AutoSuggest';
import classes from './Header.module.css'
import { generateUrl } from '../../utils/Helper';
import { _removeToken } from '../../utils/Cookie';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AgentAxios from '../../utils/AgentAxios'
import { format, formatDistanceStrict } from "date-fns";
import { IPagination } from "../../utils/Interfaces";
import { useInView } from "react-intersection-observer";
import id from "date-fns/locale/id";
import FloatingProfile from './FloatingProfile';

interface IProps {
    lang: any
    isTransparent?: boolean
    isBlue?: boolean
    langCode?: string
    withAds?: boolean
    isRestrictedFullWidth?: boolean
    sidebarZIndex?: string
}

interface INotif {
    data: {
        project_id: number
        folder_id: number
    }
    is_read: number
    text: string
    type: string
    created_at: Date | null
}

export default function Header({ lang, isTransparent, isBlue, langCode, withAds, isRestrictedFullWidth, sidebarZIndex }: IProps) {
    const [username, setUsername] = useState('')
    const [openSideBar, setOpenSideBar] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [isReady, setIsReady] = useState(false)
    const [currentPage, setCurrentPage] = useState('')
    const [notifData, setNotifData] = useState<INotif[]>([])
    const [isDropDownOpen, setIsDropDownOpen] = useState(false)
    const [isNotificationOpen, setIsNotificationOpen] = useState(false)
    const [notifOpened, setNotifOpened] = useState(false)
    const [profileOpened, setProfileOpened] = useState(false)
    const [isLoggedInAlt, setIsLoggedInAlt] = useState(false)
    const [typeLogin, setTypeLogin] = useState('')
    const { ref, inView } = useInView({
        threshold: 0,
    })
    const [profilePhoto, setProfilePhoto] = useState('')

    useEffect(() => {
        setIsMobile(!isLg())
        window.addEventListener('click', closeDropDown)
        window.addEventListener('scroll', handleScroll)
        window.addEventListener('resize', handleResize)
        setIsReady(true)
        setCurrentPage(window.location.pathname)
        return () => {
            window.removeEventListener('click', closeDropDown)
            window.removeEventListener('scroll', handleScroll)
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const handleScroll = () => {
        if (document.documentElement.scrollTop > 38 && !isScrolled) {
            setIsScrolled(true)
        } else {
            setIsScrolled(false)
        }
    }

    const handleResize = () => {
        setIsMobile(!isLg())
    }

    const toggleDropDown = (e: any) => {
        e.stopPropagation()
        closeNotification()
        setIsDropDownOpen(prev => !prev)
    }

    const closeDropDown = () => {
        setIsDropDownOpen(false)
    }

    const toggleNotification = (e: any) => {
        e.stopPropagation()
        closeDropDown()
        setIsNotificationOpen(prev => !prev)
    }

    const closeNotification = () => {
        setIsNotificationOpen(false)
    }

    const renderName = () => {
        try {
            const name = window.localStorage.getItem('username')
            if (!name) return ''
            return `${name.slice(0, 10)}${name.length > 10 ? '..' : ''}`
        } catch {
            // console.error('Cookies disabled')
            return ''
        }
    }

    const renderPhoto = () => {
        try {
            const photo = window.localStorage.getItem('photo')
            setProfilePhoto(photo || '')
        } catch {
            // console.error('Cookies disabled')
        }
    }

    const user_slug = () => {
        try {
            const slug = window.localStorage.getItem('user_slug')
            if (!slug) return ''
            return slug
        } catch {
            // console.error('Cookies disabled')
            return ''
        }
    }

    const user_image = () => {
        try {
            const image = window.localStorage.getItem('profile_picture')
            if (!image) return ''
            return image
        } catch {
            // console.error('Cookies disabled')
            return ''
        }
    }

    const logout = () => {
        try {
            localStorage.removeItem('token')
            localStorage.removeItem('expired_in')
            localStorage.removeItem('username')
            localStorage.removeItem('is_owner')
            localStorage.removeItem('profile_picture')
            localStorage.removeItem('user_slug')
            localStorage.removeItem('photo')
            localStorage.removeItem('email')
            localStorage.removeItem('phone')
            localStorage.removeItem('affiliate_status')
            localStorage.removeItem('fetch_profile')
            localStorage.removeItem('is_profile_completed')
            localStorage.removeItem('user_id')
            localStorage.removeItem('affiliate')
            sessionStorage.removeItem('prevUrl')
            _removeToken()
        } catch {
            // console.error('Cookies disabled')
        }

        location.reload()
    }

    const isLoggedIn = () => {
        let isLogin = false

        try {
            const token = localStorage.getItem('token')
            if (token) isLogin = true
        } catch {
            // console.error('Cookies disabled')
        }

        return isLogin
    }

    useEffect(() => {
        renderPhoto()

        try {
            const token = localStorage.getItem('token')
            if (token) setIsLoggedInAlt(true)
        } catch {
            // console.error('Cookies disabled')
        }
    }, [])

    // console.log(isLoggedIn())

    const renderDateFormat = (date: Date) => {
        let dateString = ''
        const formattedDate = formatDistanceStrict(new Date(date), new Date(), { unit: 'day' })
        if (date) {
            if (formattedDate === '0 days') {
                dateString = 'Hari ini'
            } else if (formattedDate === '1 days') {
                dateString = 'Kemarin'
            } else if (formattedDate === '2 days') {
                dateString = '2 hari yang lalu'
            } else if (formattedDate === '3 days') {
                dateString = '3 hari yang lalu'
            } else if (formattedDate === '4 days') {
                dateString = '4 hari yang lalu'
            } else if (formattedDate === '5 days') {
                dateString = '5 hari yang lalu'
            } else if (formattedDate === '6 days') {
                dateString = '6 hari yang lalu'
            } else if (formattedDate === '7 days') {
                dateString = '7 hari yang lalu'
            } else {
                dateString = format(new Date(date), 'dd MMMM yyyy', { locale: id })
            }
        }
        return dateString
    }

    const renderNotification = () => {
        return (
            <>
                <div className='text-xl sticky border-b md:border-none md:relative bg-white shadow-md md:shadow-none py-3 md:py-0 top-0 pl-2 font-bold text-black-222 mb-2 md:mb-5 flex'>
                    {/* <FontAwesomeIcon onClick={(e) => toggleNotification(e)} icon="angle-left" width="" size="lg" className='md:hidden mr-2' /> */}
                    <FontAwesomeIcon onClick={(e) => toggleNotification(e)} icon="angle-left" size="lg" className='md:hidden mr-2' />
                    <p className=''>Notifikasi</p>
                </div>
                {
                    notifData.length ?
                        notifData.map((data, idx) =>
                            <div ref={ref} className='px-2 md:px-0'>
                                <div onClick={() => handleURL(data.data.project_id, data.data.folder_id)} className={`rounded-xl ${data.is_read === 0 && 'bg-main-lighter'} flex items-baseline px-2 pb-2 mb-2`}>
                                    <span className={`${!data.is_read ? 'text-main' : 'text-gray-88'} text-xl mr-2`}>&#8226;</span>
                                    <div>
                                        <p className='text-footer-black'>{data.text}</p>
                                        <p className='text-gray-88 text-xs'>{data.created_at ? renderDateFormat(data.created_at) : ''}</p>
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        <div className='pl-2 text-black-222'>
                            Tidak Ada Notifikasi
                        </div>
                }

            </>
        )
    }

    const [pagination, setPagination] = useState<IPagination>({
        current_page: 1,
        from: 1,
        to: 1,
        last_page: 1,
        total: 0,
        per_page: 1,
        next_page_url: false,
    });

    const [unread, setUnread] = useState(0)

    // const fetchUnread = () => {
    //     AgentAxios.get(`${process.env.NEXT_PUBLIC_PHP_API_URL}/project-drive-notification/unread`)
    //         .then(res => {
    //             setUnread(res.data.total)
    //         })
    //         .catch(err => {
    //             unauthorizedErrorHandler('redirect', generateUrl('id', '/agent'))
    //         })
    // }

    const fetchData = (concat?: boolean) => {

        const isConcat = concat && pagination.current_page !== 1;

        const data = {
            page: pagination.current_page,
        };
        AgentAxios.get(`${process.env.NEXT_PUBLIC_PHP_API_URL}/project-drive-notification/list`, { params: data })
            .then(res => {
                const resData: INotif[] = res.data.data

                if (isConcat) {
                    const oldData = notifData;
                    const newData = oldData.concat(resData);
                    setNotifData(newData);
                } else {
                    setNotifData(resData)
                }

                // setNotifData(resData)

                setPagination((prev) => ({
                    ...prev,
                    last_page: res.data.last_page,
                    total: res.data.total,
                    next_page_url: res.data.next_page_url,
                }));
            })
    }

    const handleURL = (projectId: number, folderId: number) => {
        window.location.href = `${process.env.NEXT_PUBLIC_MAINSITE_URL}/agent/drive?project=${projectId}&folder=${folderId}`
    }

    const moreData = (page: number | string) => {
        if (page !== pagination.current_page) {
            const newPage =
                page === "prev"
                    ? pagination.current_page - 1
                    : page === "next"
                        ? pagination.current_page + 1
                        : (page as number);
            setPagination((prev) => ({
                ...prev,
                current_page: newPage,
            }));
        }
    };

    const handleNotifScroll = (e: any) => {
        // Check if user already scrolled to the bottom
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            moreData('next')
        }
    }

    useEffect(() => {
        if (isLoggedIn()) {
            // fetchUnread()
        }

        try {
            const name = window.localStorage.getItem('username')
            const typeLogin = window.localStorage.getItem('type_login')
            setUsername(name ? name : '')
            setTypeLogin(typeLogin ? typeLogin : '')
        } catch {
            setUsername('name')
        }
    }, [])

    useEffect(() => {
        if (isNotificationOpen && !notifOpened) {
            fetchData(false)
            setNotifOpened(true)
        }
    }, [isNotificationOpen])

    useEffect(() => {
        if (pagination.current_page !== 1) {
            fetchData(true)
        }
    }, [pagination.current_page]);

    useEffect(() => {
        if (inView && pagination.next_page_url) moreData('next')
    }, [inView, pagination.next_page_url])

    return (
        <>
            {
                profileOpened &&
                <FloatingProfile logout={logout} />
            }

            <div className={`${withAds ? !isScrolled ? 'absolute' : 'fixed' : 'fixed'} top-0 px-2 ${isTransparent ? 'py-5' : 'py-2'} w-full z-20 transition-colors duration-300 ease-in-out ${isTransparent ? isScrolled ? 'bg-white shadow-header' : '' : isBlue ? 'bg-white shadow-header' : 'bg-white shadow-header'} ${isRestrictedFullWidth && 'flex justify-center'}`}>
                <div onScroll={handleNotifScroll} className={`absolute md:hidden text-sm bottom-0 right-0 pb-5 bg-white border w-full border-gray-0033 shadow transform bg-white translate-y-full transition duration-200 ease-linear rounded-3px overflow-auto ${isNotificationOpen ? '' : classes['dropdown-close']} ${classes['h-93vh']}`}>
                    {renderNotification()}
                </div>
                <div className={`${isRestrictedFullWidth && classes.isRestrictedFullWidth} flex relative items-center ${(isTransparent || isBlue) && !isMobile ? 'container' : ''}`}>
                    <a href={process.env.NEXT_PUBLIC_MAINSITE_URL} className={`cursor-pointer ${!isTransparent && 'lg:mr-4'}`}>
                        <img src={`/static/images/svg/sinarmas_logo.svg`} alt="logo" className={`${classes.logo}`} />
                        {/* <img src={isTransparent || isBlue ? `${process.env.NEXT_PUBLIC_MAINSITE_URL}/assets/images/svg/sinarmas_logo.svg` : `${process.env.NEXT_PUBLIC_MAINSITE_URL}/assets/images/svg/logo.svg`} alt="logo" style={{ width: "175px", height: "38px" }} /> */}
                    </a>
                    {
                        !isTransparent && !isBlue ?
                            <div className="relative hidden lg:block">
                                <AutoSuggest />
                            </div>
                            : null
                    }
                    <div className={`flex items-center ${(typeLogin === 'user' && isLoggedIn()) ? 'm-auto' : 'ml-auto'}`}>
                        <div key="links" className={`flex-row items-center ${isTransparent ? 'text-black-#3D3D3D ml-4' : isBlue ? 'text-black-#3D3D3D ml-4' : 'text-black-#3D3D3D ml-4 mr-4'} hidden md:flex relative`}>
                            <a href={`${generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/infiniteliving`)}`} className={`mx-4 lg:mx-6 transition-all duration-200 ease-header relative ${classes.navItem} ${'hover:text-main-dark active:text-main-dark'}`}>
                                <span className={`flex-row items-center`}>
                                    <img loading="lazy" width="20" height="20" src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/promotion.svg`} alt="Promotion Icon" className='inline mr-1' />
                                    Promotion
                                </span>
                            </a>
                            <a href={`${generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/sinar-mas-pillars`)}`} className={`mx-4 lg:mx-6 transition-all duration-200 ease-header relative ${classes.navItem} ${'hover:text-main-dark active:text-main-dark'}`}>Sinar Mas Pillars</a>
                            <a href={`${generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/article`)}`} className={`mx-4 lg:mx-6 transition-all duration-200 ease-header relative ${classes.navItem} ${'hover:text-main-dark active:text-main-dark'}`}>Articles</a>
                            <a href={`${generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/maps`)}`} className={`mx-4 lg:mx-6 transition-all duration-200 ease-header relative ${classes.navItem} ${'hover:text-main-dark active:text-main-dark'}`}>{lang.map}</a>
                            <a href={`${generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/search`)}`} className={`ml-4 lg:ml-6 transition-all duration-200 ease-header relative ${classes.navItem} ${currentPage.includes('search') ? classes.navItemActive + ' text-main-dark' : ''} ${'hover:text-main-dark active:text-main-dark'}`}>{lang.browse_unit}</a>
                            {
                                isLoggedIn() ?
                                    null
                                    :
                                    <span className='ml-4 lg:ml-6'>
                                        <a href={`${generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/signin`)}`} className={`ml-4 lg:ml-6 transition-all duration-200 ease-header relative ${classes.navItem} ${'hover:text-main-dark active:text-main-dark'}`}>Login</a>
                                        <span className='mx-2'>/</span>
                                        <a href={`${generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/signup`)}`} className={`transition-all duration-200 ease-header relative ${classes.navItem} ${'hover:text-main-dark active:text-main-dark'}`}>Sign Up</a>
                                    </span>
                            }
                        </div>
                        {
                            isLoggedIn() &&
                            typeLogin !== 'user' &&
                            <div key="login-dropdown" className="relative login-dropdown hidden md:flex">
                                {
                                    (isLoggedIn()) &&
                                    <button onClick={(e) => toggleDropDown(e)} className={`${classes['dropdown-toggle']} ${isDropDownOpen ? classes['dropdown-toggle-open'] : ''} ${'text-black-#3D3D3D'} flex flex-row items-center outline-none focus:outline-none w-full ml-8`}>
                                        <img src={profilePhoto && profilePhoto !== 'null' ? profilePhoto : `${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/img_avatar.png`} width="24" height="24" alt="profile" className={`mr-1 border h-6 w-6 ${user_image() ? 'rounded-full object-cover object-center border-main' : 'border-transparent'}`} />
                                        <p className="ml-px mr-2">{renderName()}</p>
                                    </button>
                                }
                                <div className={`absolute text-sm bottom-0 right-0 -mb-4 -mr-2 py-2 bg-white border border-gray-0033 shadow transform translate-y-full transition duration-200 ease-linear rounded-3px ${isDropDownOpen ? '' : classes['dropdown-close']} ${classes['w-200px']}`}>
                                    <a href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/agent/${user_slug()}`} className="text-black-48 text-sm flex flex-row py-2 px-4 hover:bg-#E20A1714 items-center focus:outline-none">
                                        <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/icon-SellerPage.svg`} alt="heart" className="mr-2 w-5 h-5 object-contain" width="16" height="16" />
                                        <p>My Page</p>
                                    </a>
                                    <a href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/agent/dashboard`} className="text-black-48 text-sm flex flex-row py-2 px-4 hover:bg-#E20A1714 items-center focus:outline-none">
                                        <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/icon-Dashboard.svg`} alt="heart" className="mr-2 w-5 h-5 object-contain" width="16" height="16" />
                                        <p>Dashboard</p>
                                    </a>
                                    <a href={generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/agent/enquiry-list`)} className="text-black-48 text-sm flex flex-row py-2 px-4 hover:bg-#E20A1714 items-center focus:outline-none">
                                        <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/icon-enquiryList.svg`} alt="account" className="mr-2 w-5 h-5 object-contain" width="16" height="16" />
                                        <p>Enquiry List</p>
                                    </a>
                                    <a href={generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/agent/drive`)} className="text-black-48 text-sm flex flex-row py-2 px-4 hover:bg-#E20A1714 items-center focus:outline-none">
                                        <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/drive.svg`} alt="account" className="ml-0.5 mr-10px w-4 h-4 object-contain" width="16" height="16" />
                                        <p>eCatalog Drive</p>
                                    </a>
                                    <a href={generateUrl(typeof langCode !== "undefined" ? langCode : "id", `/agent/account`)} className="text-black-48 text-sm flex flex-row py-2 px-4 hover:bg-#E20A1714 items-center focus:outline-none">
                                        <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/icon-navigation-user_24px.svg`} alt="account" className="mr-2 w-5 h-5 object-contain" width="16" height="16" />
                                        <p>Account</p>
                                    </a>
                                    <hr className="border-gray-0000001A mx-4 my-1" />
                                    <button name='logout' onClick={() => logout()} className="text-black-48 text-sm flex flex-row py-2 px-4 w-full outline-none focus:outlline-none hover:bg-#E20A1714 items-center focus:outline-none">
                                        <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/icon-navigation-login_24px.svg`} alt="signout" className="mr-2 w-5 h-5 object-contain" width="16" height="16" />
                                        <p>Log Out</p>
                                    </button>
                                </div>
                            </div>
                        }
                        {
                            isLoggedIn() &&
                            typeLogin !== 'user' &&
                            <div key="notification" onClick={(e) => toggleNotification(e)} className={`invisible md:visible ml-4 lg:ml-6 transition-all duration-200 hover:border rounded ease-header relative px-2 cursor-pointer hover:bg-gray-e9 ${classes.navItem}`} >
                                <FontAwesomeIcon icon="bell" width="0" size="sm" className='' />
                                {
                                    unread !== 0 &&
                                    <div className={`absolute bg-main text-white rounded-full text-center ${classes.unread}`}>
                                        {unread}
                                    </div>
                                }
                                <div className={`absolute text-sm bottom-0 right-0 -mb-4 -mr-2 px-3 py-5 bg-white border border-gray-0033 shadow transform translate-y-full transition duration-200 ease-linear rounded-3px overflow-auto ${isNotificationOpen ? '' : classes['dropdown-close']} ${classes.notif} `}>
                                    {renderNotification()}
                                </div>
                            </div>
                        }
                    </div>

                    {/* {
                        renderName() !== 'Yosef' && */}
                    {
                        isLoggedIn() &&
                        (typeLogin === 'user' && !isMobile) &&
                        <div className={`cursor-pointer`}>
                            <div onClick={() => setProfileOpened(profileOpened ? false : true)} className='flex items-center cursor-pointer'>
                                <img src={profilePhoto && profilePhoto !== 'null' ? profilePhoto : `${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/img_avatar.png`} className="object-cover object-center rounded-full flex-shrink-0 mr-2" style={{ width: 30, height: 30 }} alt="avatar" />
                                <p className='text-black-#3D3D3D'>{renderName()}</p>
                            </div>
                        </div>
                    }
                    {/* } */}

                    <div className={`flex flex-row items-center ml-auto md:ml-0 absolute right-0 md:relative md:right-auto`}>
                        <div className="flex-row items-center hidden md:flex">
                        </div>
                        {
                            isLoggedIn() &&
                            typeLogin !== 'user' &&
                            <div key="notification" onClick={(e) => toggleNotification(e)} className={`md:hidden ml-4 lg:ml-6 transition-all duration-200 hover:border rounded ease-header relative px-2 cursor-pointer hover:bg-gray-e9 ${classes.navItem}`}>
                                <FontAwesomeIcon icon="bell" width="0" size="sm" className='' />
                                {
                                    unread !== 0 &&
                                    <div className={`absolute md:hidden bg-main text-white rounded-full text-center ${classes.unread}`}>
                                        {unread}
                                    </div>
                                }
                            </div>
                        }
                        <button
                            id='button_sidebar_option'
                            title='Button Sidebar'
                            className={`md:hidden hamburger hamburger--slider ${openSideBar ? 'is-active' : ''}`}
                            onClick={() => setOpenSideBar(!openSideBar)}
                            // style={{filter: (isTransparent && !isScrolled) ? `brightness(0) saturate(100%) invert(100%)` : undefined}}
                            type="button"
                        >
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            {
                isReady ?
                    isLoggedIn() ?
                        typeLogin === 'user' ?
                            <SidebarUser
                                langCode={langCode ? langCode : "id"}
                                lang={lang}
                                show={openSideBar}
                                white
                                onClickClose={() => setOpenSideBar(false)}
                                sidebarZIndex={sidebarZIndex}
                                isLoggedIn={isLoggedIn()}
                                user_slug={user_slug()}
                                logout={logout}
                            />
                            : <Sidebar
                                langCode={langCode ? langCode : "id"}
                                lang={lang}
                                show={openSideBar}
                                white
                                onClickClose={() => setOpenSideBar(false)}
                                sidebarZIndex={sidebarZIndex}
                                isLoggedIn={isLoggedIn()}
                                user_slug={user_slug()}
                                logout={logout}
                            />
                        : <Sidebar
                            langCode={langCode ? langCode : "id"}
                            lang={lang}
                            show={openSideBar}
                            white
                            onClickClose={() => setOpenSideBar(false)}
                            sidebarZIndex={sidebarZIndex}
                            isLoggedIn={isLoggedIn()}
                            user_slug={user_slug()}
                            logout={logout}
                        />
                    : null
            }
        </>
    )
}