import React, { useState, ReactNode, useEffect, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import classes from './LegacyCarousel.module.css'

interface IProps {
    show: number
    showSliderTranslateOverride?: number
    children?: ReactNode[]
    sliderClass?: string
    isRepeatedProps?: boolean
    withIndicator?: boolean
    indicatorSpacing?: string
    indicatorContainerClasses?: string
    indicatorClasses?: {
        active?: string
        passive?: string
        far?: string
    }
    leftArrow?: (prev: () => void) => void
    rightArrow?: (prev: () => void) => void
    withoutArrow?: boolean
    containerClasses?: string
    autoSlide?: boolean | number
    indicatorSpacerConstant?: number
    selectedIndex?: Function
    renderChildren?: (next: () => void, prev: () => void) => ReactNode[]
    sliderOffset?: number
    fixedOffset?: number
    withoutHideOverflow?: boolean
}

const LegacyCarousel = ({ show, showSliderTranslateOverride, children, sliderClass, isRepeatedProps, withIndicator, indicatorSpacing, indicatorContainerClasses, indicatorClasses, leftArrow, rightArrow, withoutArrow, containerClasses, autoSlide, indicatorSpacerConstant, selectedIndex, renderChildren, sliderOffset, fixedOffset, withoutHideOverflow }: IProps) => {
    const indicatorRef = useRef<HTMLDivElement>(null)

    const actualChildren = useMemo(() => {
        if (children) return children

        if (renderChildren) return renderChildren(() => { }, () => { })

        return []
    }, [children, renderChildren])

    const [isRepeated, setIsRepeated] = useState(isRepeatedProps && actualChildren.length > show)

    const [state, setState] = useState({
        activeIndex: isRepeated ? show : 0
    })

    const [touchPosition, setTouchPosition] = useState<null | number>(null)

    const [length, setLength] = useState(actualChildren.length)

    useEffect(() => {
        const localIsRepeated = isRepeatedProps && actualChildren.length > show
        setIsRepeated(localIsRepeated)
        setState(prev => ({
            ...prev,
            activeIndex: localIsRepeated ? show : 0
        }))
    }, [isRepeatedProps, show])

    useEffect(() => {
        setLength(actualChildren.length)
    }, [actualChildren])

    useEffect(() => {
        if (isRepeated) {
            if (state.activeIndex === show || state.activeIndex === (length)) {
                setTransitionEnabled(true)
            }
        }

        if (withIndicator) {
            const active = indicatorRef.current?.querySelector('.legacy-carousel-active')
            if (active) {
                let index = active.getAttribute('data-index')
                if (index !== null) {
                    indicatorRef.current?.scrollTo({
                        left: ((Number(index) - 2) / 5) * (indicatorSpacerConstant || 50),
                        behavior: 'smooth',
                    })
                }
            }
        }
    }, [state.activeIndex])

    useEffect(() => {
        let interval: null | number = null

        if (autoSlide) {
            interval = window.setInterval(() => {
                nextCard()
            }, typeof autoSlide === 'number' ? autoSlide : 5000)
        }

        if (selectedIndex) selectedIndex(state.activeIndex)

        return () => {
            if (interval) window.clearInterval(interval)
        }

    }, [autoSlide, state.activeIndex])

    useEffect(() => {
        if (isRepeated) {
            if (state.activeIndex > (length + show)) {
                setState(prev => ({
                    ...prev,
                    activeIndex: show,
                }))
            }
        }
    }, [state.activeIndex, isRepeated, length, show])

    const nextCard = () => {
        if (isRepeated || state.activeIndex < (length - show)) {
            let newActiveIndex = state.activeIndex;
            newActiveIndex = newActiveIndex + 1;
            setState({ activeIndex: newActiveIndex });
        }
    }

    const prevCard = () => {
        if (isRepeated || state.activeIndex > 0) {
            let newActiveIndex = state.activeIndex;
            newActiveIndex = newActiveIndex - 1;
            setState({ activeIndex: newActiveIndex });
        }
    }

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        e.stopPropagation()
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        e.stopPropagation()
        const touchDown = touchPosition
        if (touchDown === null) {
            return
        }

        const touchUp = e.touches[0].clientX
        const diff = touchDown - touchUp

        if (diff > 5) {
            nextCard()
        }

        if (diff < -5) {
            prevCard()
        }

        setTouchPosition(null)
    }

    const [transitionEnabled, setTransitionEnabled] = useState(true)

    const handleTransitionEnd = () => {
        if (isRepeated) {
            if (state.activeIndex === 0) {
                setTransitionEnabled(false)
                setState(prev => ({
                    ...prev,
                    activeIndex: length
                }))
            } else if (state.activeIndex === length + show) {
                setTransitionEnabled(false)
                setState(prev => ({
                    ...prev,
                    activeIndex: show
                }))
            }
        }
    }

    const renderExtraPrev = () => {
        let output = []
        for (let index = 0; index < show; index++) {
            output.push(actualChildren[length - 1 - index])
        }
        output.reverse()
        return output
    }

    const renderExtraNext = () => {
        let output = []
        for (let index = 0; index < show; index++) {
            output.push(actualChildren[index])
        }
        return output
    }

    const renderDots = () => {
        let output = []
        const localShow = isRepeated ? show : 0
        const localLength = isRepeated ? length : Math.ceil(length / show) - (show - 1)
        const calculatedActiveIndex = (state.activeIndex - localShow) < 0 ? (length + (state.activeIndex - localShow)) : state.activeIndex - localShow
        for (let index = 0; index < localLength; index++) {
            let className = ''
            if (calculatedActiveIndex === index) {
                className = indicatorClasses?.active || classes.active
            } else {
                if (calculatedActiveIndex === 0) {
                    if (calculatedActiveIndex + index <= 2) {
                        className = indicatorClasses?.passive || classes.passive
                    } else {
                        className = indicatorClasses?.far || classes.far
                    }
                } else if (calculatedActiveIndex === localLength - 1) {
                    if (Math.abs(calculatedActiveIndex - index) <= 2) {
                        className = indicatorClasses?.passive || classes.passive
                    } else {
                        className = indicatorClasses?.far || classes.far
                    }
                } else {
                    if (Math.abs(calculatedActiveIndex - index) === 1) {
                        className = indicatorClasses?.passive || classes.passive
                    } else {
                        className = indicatorClasses?.far || classes.far
                    }
                }
            }
            output.push(
                <div key={index} data-index={index} className={`rounded-full ${calculatedActiveIndex === index ? 'legacy-carousel-active' : ''} ${className}`} />
            )
        }
        return output
    }

    return (
        <div className={`relative my-0 mx-auto flex flex-col ${containerClasses}`}>
            <div className="flex">
                {
                    ((isRepeated || (state.activeIndex > 0)) && !withoutArrow) ?
                        leftArrow ?
                            leftArrow(prevCard)
                            :
                            <div className="hidden lg:block absolute left-0 z-1 transform top-1/2 -translate-y-1/2 -translate-x-1/2">
                                <button
                                    onClick={() => prevCard()}
                                    className="bg-white hover:bg-gray-efef transform active:scale-90 shadow-lg relative h-10 w-10 rounded-full text-xl text-white outline-none focus:outline-none transition-all duration-150 linear"
                                >
                                    <FontAwesomeIcon icon="angle-left" size="lg" width="0" className="absolute inset-0 mx-auto my-auto text-gray-77" />
                                </button>
                            </div>
                        : null
                }
                <div className={`${withoutHideOverflow ? '' : 'overflow-hidden'} lg:p-0 h-full w-full`} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
                    <div onTransitionEnd={() => handleTransitionEnd()} className={`flex ${transitionEnabled ? 'transition-all' : 'transition-none'} duration-300 ease-in-out ${sliderClass || ''} no-scrollbar-all`} style={{ transform: `translateX(calc(-${state.activeIndex * (fixedOffset || (100 / (showSliderTranslateOverride || show)))}${fixedOffset ? 'px' : '%'} + ${(sliderOffset || 0) * state.activeIndex}px))` }}>
                        {
                            (length > show && isRepeated) &&
                            renderExtraPrev()
                        }
                        {
                            renderChildren ? renderChildren(nextCard, prevCard) : children
                        }
                        {
                            (length > show && isRepeated) &&
                            renderExtraNext()
                        }
                    </div>
                </div>
                {
                    ((isRepeated || (state.activeIndex < length - show)) && !withoutArrow) ?
                        rightArrow ?
                            rightArrow(nextCard)
                            :
                            <div className="hidden lg:block absolute right-0 z-1 transform top-1/2 -translate-y-1/2 translate-x-1/2">
                                <button
                                    onClick={() => nextCard()}
                                    className="bg-white hover:bg-gray-efef transform active:scale-90 shadow-lg relative h-10 w-10 rounded-full text-xl text-white outline-none focus:outline-none transition-all duration-150 linear"
                                >
                                    <FontAwesomeIcon icon="angle-right" size="lg" width="0" className="absolute inset-0 mx-auto my-auto text-gray-77" />
                                </button>
                            </div>
                        : null
                }
            </div>
            {
                withIndicator &&
                <div ref={indicatorRef} className={`flex flex-row items-center mx-auto no-scrollbar-all ${indicatorContainerClasses} ${indicatorSpacing} ${classes.dots}`}>
                    {
                        renderDots()
                    }
                </div>
            }
        </div>
    )
}

export default LegacyCarousel
