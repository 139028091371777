import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { generateUrl, getCurrentBreakpoint } from "../../utils/Helper";
import classes from './Footer.module.css'
import headerClasses from './Header.module.css'
import Image from "next/image";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

interface IProps {
    lang: any
    sellerPage?: boolean
    extraFooter?: boolean
}

export default function Footer({ lang, sellerPage, extraFooter }: IProps) {
    const [isMobile, setIsMobile] = useState(false)
    const [extraFooterType, setExtraFooterType] = useState<keyof ExtraFooter>("specialPropertyOffer")
    const [linkFooterLimit, setLinkFooterLimit] = useState(20)

    const isLoggedIn = () => {
        let isLogin = false

        try {
            const token = localStorage.getItem('token')
            if (token) isLogin = true
        } catch {
            // console.error('Cookies disabled')
        }

        return isLogin
    }
    useEffect(() => {
        let breakpoint = getCurrentBreakpoint()
        setIsMobile(breakpoint === 'xs' || breakpoint === 'sm')
    }, [])

    const renderExtraFooter = (footerType: keyof ExtraFooter, limit: number) => {
        const handleExpand = () => setLinkFooterLimit(100)

        if (isMobile) {
            let mobileLimit = limit - 15
            return (
                <div className="relative bg-footer-black py-10">
                    <div className="relative overflow-y-scroll">
                        <div
                            id="extraFooter"
                            className="mx-5 inline-block whitespace-no-wrap"
                        >
                            {
                                Object.entries(extraFooterLink).map((item, index) => (
                                    <div key={index} className="inline-block w-240px">
                                        <div className="flex flex-row">
                                            <h4
                                                onClick={() => {
                                                    setExtraFooterType(item[0] as keyof ExtraFooter)
                                                    setLinkFooterLimit(20)
                                                }}
                                                className={`block cursor-pointer relative text-base pb-2 text-white font-bold cursorPointer ${headerClasses.navItem} ${footerType === item[0] ? headerClasses.navItemActive : ''}`}
                                            >
                                                {item[1].name}
                                            </h4>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>5
                    <div className={`container transition-all duration-150 grid ${mobileLimit === 5 ? 'grid-rows-5 grid-flow-col' : ''} grid-cols-1 gap-6 row-gap-1 mt-4 text-white-80`}>
                        {
                            extraFooterLink[footerType].links.map((link) => (
                                <div className="w-full text-white-80">
                                    <a href={link.link} className={`my-2 text-sm self-start hover:text-white transition-all duration-200 ease-header relative ${headerClasses.elipsisLink}`} >{link.internal_link}</a>
                                </div>
                            )).filter((_, idx) => idx < mobileLimit)
                        }
                    </div>
                    <div
                        style={{
                            background: `linear-gradient(0deg, rgba(25,25,25,1) 50%, rgba(25,25,25,0) 100%)`,
                        }}
                        className={`absolute transition-opacity duration-100 ease-header bottom-0 w-full flex ${(extraFooterLink[footerType].links.length <= 5 || mobileLimit !== 5) ? 'opacity-0 h-0 py-0' : 'opacity-100 h-auto pt-20 pb-12'}`}
                    >
                        <span onClick={handleExpand} className={`mx-auto cursor-pointer block hover:text-white text-white transition-all duration-200 ease-header relative ${headerClasses.navItem}`}>
                            Tampilkan lebih banyak
                            <FontAwesomeIcon icon="caret-down" className="ml-2" />
                        </span>
                    </div>
                </div>
            )
        }
        return (
            <div className="relative bg-footer-black py-10 md:py-16 overflow-hidden md:overflow-visible md:pt-8">
                <div className={`mx-auto flex flex-row flex-no-wrap md:grid grid-cols-5 gap-6 ${headerClasses.extraFooter}`}>
                    {
                        Object.entries(extraFooterLink).map((item) => (
                            <div className="flex flex-col">
                                <h4
                                    onClick={() => {
                                        setExtraFooterType(item[0] as keyof ExtraFooter)
                                        setLinkFooterLimit(20)
                                    }}
                                    className={`block self-start cursor-pointer relative text-base pb-2 text-white font-bold cursorPointer ${headerClasses.navItem} ${footerType === item[0] ? headerClasses.navItemActive : ''}`}
                                >
                                    {item[1].name}
                                </h4>
                            </div>
                        ))
                    }
                </div>
                <div className={`mx-auto transition-all duration-150 grid grid-flow-col ${limit === 20 ? 'grid-rows-4' : 'grid-rows-6'} grid-cols-5 gap-6 row-gap-1 mt-4 text-white-80 ${headerClasses.extraFooter}`}>
                    {
                        extraFooterLink[footerType].links.map((link) => (
                            <div className="w-full text-white-80">
                                <a href={link.link} className={`my-2 text-sm self-start hover:text-white transition-all duration-200 ease-header relative ${headerClasses.elipsisLink}`} >{link.internal_link}</a>
                            </div>
                        )).filter((_, idx) => idx < limit)
                    }
                </div>
                <div
                    style={{
                        background: `linear-gradient(0deg, rgba(25,25,25,1) 50%, rgba(25,25,25,0) 100%)`,
                    }}
                    className={`absolute transition-opacity duration-100 ease-header bottom-0 w-full flex ${(extraFooterLink[footerType].links.length <= 20 || limit !== 20) ? 'opacity-0 h-0 py-0' : 'opacity-100 h-auto pt-24 pb-10'}`}
                >
                    <span onClick={handleExpand} className={`mx-auto cursor-pointer block hover:text-white text-white transition-all duration-200 ease-header relative ${headerClasses.navItem}`}>
                        Tampilkan lebih banyak
                        <FontAwesomeIcon icon="caret-down" className="ml-2" />
                    </span>
                </div>
            </div>
        )
    }

    return (
        <footer>
            {extraFooter ? renderExtraFooter(extraFooterType, linkFooterLimit) : null}

            <div className="flex justify-end relative -mt-6">
                <div className={`absolute z-1 left-0 bottom-0 bg-footer-red ${classes.ribbon_left}`} />
                <div className={`absolute z-2 right-0 bottom-0 bg-footer-black ${classes.ribbon_right}`} />
                <div
                    style={{ aspectRatio: '3/1' }}
                    className={`relative z-3 ${classes.ribbon_image}`}
                >
                    <Image
                        src="/static/images/footer-ribbon.png"
                        layout="fill"
                        alt="Red Ribbon"
                    />
                </div>
            </div>
            <div className="bg-footer-black py-10 md:py-20 -mt-px">
                <div className="container flex flex-wrap">
                    <div className="w-full lg:w-2/5 text-off-white">
                        <h4 className="text-lg pb-4 text-white font-bold">Sinar Mas Land Plaza</h4>
                        <p className="text-lg pb-4">Jl. Grand Boulevard, BSD City Tangerang,<br className="hidden lg:inline xl:hidden" /> Banten,<br className="hidden xl:inline" /> Indonesia 15345</p>
                        {/* <p className="text-sm pb-4">+6221-50588221<br/>+6221-50368368</p> */}

                    </div>
                    <div className="w-full lg:hidden">
                        <hr className="border-t border-footer-divider mt-2 mb-4" />
                    </div>
                    <div className="w-full lg:w-1/5">
                        <h4 className="block text-lg pb-2 text-white font-bold">E-Catalog</h4>
                        <div className="flex md:flex-col flex-row">
                            <div className="md:w-full w-1/2 flex flex-col text-white-80">
                                <a href="https://www.sinarmasland.com/?utm_source=ecatalog&utm_medium=ecatalogOrganic&utm_campaign=ecatalog_footer&utm_content=2024_07_eft_001_home" className={`my-2 self-start hover:text-white transition-all duration-200 ease-header relative ${headerClasses.navItem}`}>Home</a>
                                <a href="https://www.sinarmasland.com/development/?utm_source=ecatalog&utm_medium=ecatalogOrganic&utm_campaign=ecatalog_footer&utm_content=2024_07_eft_002_development" className={`my-2 self-start hover:text-white transition-all duration-200 ease-header relative ${headerClasses.navItem}`}>Development</a>
                                <a href="https://www.sinarmasland.com/about-us/?utm_source=ecatalog&utm_medium=ecatalogOrganic&utm_campaign=ecatalog_footer&utm_content=2024_07_eft_003_corporate" className={`my-2 self-start hover:text-white transition-all duration-200 ease-header relative ${headerClasses.navItem}`}>Corporate</a>
                            </div>
                            <div className="md:w-full w-1/2 flex flex-col text-white-80">
                                <a href="https://www.sinarmasland.com/sustainability/?utm_source=ecatalog&utm_medium=ecatalogOrganic&utm_campaign=ecatalog_footer&utm_content=2024_07_eft_004_sustainability" className={`my-2 self-start hover:text-white transition-all duration-200 ease-header relative ${headerClasses.navItem}`}>Sustainability</a>
                                <a href="https://www.sinarmasland.com/investors/?utm_source=ecatalog&utm_medium=ecatalogOrganic&utm_campaign=ecatalog_footer&utm_content=2024_07_eft_005_investors" className={`my-2 self-start hover:text-white transition-all duration-200 ease-header relative ${headerClasses.navItem}`}>Investors</a>
                                <a href={generateUrl('en', '/infiniteliving')} className={`my-2 self-start hover:text-white transition-all duration-200 ease-header relative ${headerClasses.navItem}`}>Infinite Living</a>
                            </div>
                        </div>
                    </div>

                    <div className="w-full lg:hidden">
                        <hr className="border-t border-footer-divider mt-2 mb-4" />
                    </div>

                    <div className="w-full lg:w-1/5">
                        <div className="w-full flex flex-col text-white-80">
                            <h4 className="block text-lg pb-2 text-white font-bold">Product</h4>
                            <a href="/search" className={`my-2 self-start hover:text-white transition-all duration-200 ease-header relative ${headerClasses.navItem}`}>Search Unit</a>
                            <a href="/maps" className={`my-2 self-start hover:text-white transition-all duration-200 ease-header relative ${headerClasses.navItem}`}>Search Cluster</a>
                        </div>

                        {
                            isLoggedIn()
                                ? null
                                :
                                <>
                                    <div className="w-full lg:hidden">
                                        <hr className="border-t border-footer-divider mt-2 mb-4" />
                                    </div>
                                    <div className="w-full flex flex-col text-white-80 lg:mt-6">
                                        <h4 className="block text-lg pb-2 text-white font-bold">Agent</h4>
                                        <a href="/agent" className={`my-2 self-start hover:text-white transition-all duration-200 ease-header relative ${headerClasses.navItem}`}>
                                            Sales Login
                                        </a>
                                    </div>
                                </>
                        }
                    </div>

                    <div className="w-full lg:w-1/5 flex flex-col">
                        <div className="w-full lg:hidden">
                            <hr className="border-t border-footer-divider mt-2 mb-4" />
                        </div>
                        <div className="flex flex-col text-white-80">
                            <h4 className="text-lg pb-2 text-white font-bold">Contact us on</h4>
                            <div>
                                <a aria-label="Email Sinarmasland" href="mailto:ecatalog@sinarmasland.com" target="_blank" className={`flex hover:text-white transition-all duration-200 flex-row items-center self-start ease-header relative my-2`}>
                                    <div className="text-5xl w-12 mr-3">
                                        <FontAwesomeIcon icon={faEnvelope} className="w-8 h-8" />
                                    </div>
                                    <div>
                                        <p className="block font-semibold">Email</p>
                                        <p className="block underline">ecatalog@sinarmasland.com</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="w-full lg:hidden">
                            <hr className="border-t border-footer-divider mt-2 mb-4" />
                        </div>
                        <div className="flex flex-col text-white-80 md:mt-6">
                            <h4 className="text-lg pb-2 text-white font-bold">Follow us</h4>
                            <div className="flex flex-row lg:flex-col">
                                <div className="w-full flex flex-col lg:w-1/2">
                                    <a aria-label="Sinarmasland Instagram Account" href="https://www.instagram.com/sinarmas_land" className={`flex hover:text-white transition-all duration-200 flex-row items-center self-start ease-header relative my-1 ${headerClasses.navItem}`}>
                                        <div className="w-8">
                                            <FontAwesomeIcon width="0" icon={['fab', 'instagram']} />
                                        </div>
                                        <p>Instagram</p>
                                    </a>
                                    <a aria-label="Sinarmasland Twitter/X Account" href="https://twitter.com/sinarmas_land" className={`flex hover:text-white transition-all duration-200 flex-row items-center self-start ease-header relative my-1 ${headerClasses.navItem}`}>
                                        <div className="w-8"><FontAwesomeIcon width="0" icon={['fab', 'twitter-square']} /></div>
                                        <p>Twitter</p>
                                    </a>
                                </div>
                                <div className="w-full flex flex-col lg:w-1/2">
                                    <a aria-label="Sinarmasland Facebook Account" href="https://www.facebook.com/sinarmasland" className={`flex hover:text-white transition-all duration-200 flex-row items-center self-start ease-header relative my-1 ${headerClasses.navItem}`}>
                                        <div className="w-8"><FontAwesomeIcon width="0" icon={['fab', 'facebook-square']} /></div>
                                        <p>Facebook</p>
                                    </a>
                                    <a aria-label="Sinarmasland Youtube Account" href="https://www.youtube.com/channel/UCVxIPn2_4HYa18VclGzIqKg" className={`flex hover:text-white transition-all duration-200 flex-row items-center self-start ease-header relative my-1 ${headerClasses.navItem}`}>
                                        <div className="w-8"><FontAwesomeIcon className="relative" style={{ left: -2 }} width="0" icon={['fab', 'youtube']} /></div>
                                        <p>Youtube</p>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full py-4 bg-black" style={{ paddingBottom: sellerPage && isMobile ? '6rem' : '' }}>
                <div className="container flex flex-wrap">
                    <div className="w-full lg:w-1/2">
                        <p className="text-white-80 text-xs lg:text-sm text-center lg:text-left mb-1">© {new Date().getFullYear()} <strong>Sinar Mas Land </strong>All Right Reserved</p>
                    </div>
                    <div className="w-full lg:w-1/2 text-white lg:text-white-80 flex items-center text-xs lg:text-sm justify-center lg:justify-end">
                        <a href="https://sinarmasland.com/privacy-policy" className="transition-colors duration-200 hover:text-white">Privacy Policy</a>
                        <span className="text-white-80 lg:hidden">&nbsp;•&nbsp;</span>
                        <a href="https://sinarmasland.com/terms-&-conditions" className="lg:ml-4 transition-colors duration-200 hover:text-white">Term and Conditions</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

type ExtraFooterItem = {
    name: string,
    links: { internal_link: string, link: string }[]
}

type ExtraFooter = {
    specialPropertyOffer: ExtraFooterItem,
    propertySell: ExtraFooterItem,
    projectProperty: ExtraFooterItem,
    ragamProperiTipe: ExtraFooterItem,
    ragamProperti: ExtraFooterItem,
}

const extraFooterLink: ExtraFooter = {
    specialPropertyOffer: {
        name: "Properti Penawaran Khusus",
        links: [
            {
                "internal_link": "Promo Properti di BSD City",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/promo-bsdcity`
            },
            {
                "internal_link": "Promo Properti di Jakarta",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/promo-jakarta`
            },
            {
                "internal_link": "Promo Properti di Cikarang",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/promo-cikarang`
            },
            {
                "internal_link": "Promo Properti di Bogor",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/promo-bogor`
            },
            {
                "internal_link": "Promo Properti di Balikpapan",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/promo-balikpapan`
            },
            {
                "internal_link": "Promo Properti di Tangerang",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/promo-tangerang`
            },
            {
                "internal_link": "Promo Properti di Batam",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/promo-batam`
            },
            {
                "internal_link": "Promo Properti di Surabaya",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/promo-surabaya`
            }
        ]
    },
    propertySell: {
        name: "Properti Dijual di Indonesia",
        links: [
            {
                "internal_link": "Properti Dijual di BSD City",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/bsd-city`
            },
            {
                "internal_link": "Properti Dijual di Balikpapan",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/balikpapan`
            },
            {
                "internal_link": "Properti Dijual di Jakarta",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/jakarta`
            },
            {
                "internal_link": "Properti Dijual di Tangerang",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/tangerang`
            },
            {
                "internal_link": "Properti Dijual di Cikarang",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/cikarang`
            },
            {
                "internal_link": "Properti Dijual di Batam",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/batam`
            },
            {
                "internal_link": "Properti Dijual di Bogor",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/bogor`
            },
            {
                "internal_link": "Properti Dijual di Surabaya",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/surabaya`
            }
        ]
    },
    ragamProperiTipe: {
        name: "Ragam Properti di Indonesia",
        links: [
            {
                "internal_link": "Kavling Lot Dijual di Balikpapan",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/balikpapan/kavling`
            },
            {
                "internal_link": "Residential Dijual di Balikpapan",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/balikpapan/residential`
            },
            {
                "internal_link": "Ruko Dijual di Balikpapan",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/balikpapan/shophouse`
            },
            {
                "internal_link": "Apartemen Dijual di Batam",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/batam/apartment`
            },
            {
                "internal_link": "Kavling Lot Dijual di Batam",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/batam/kavling`
            },
            {
                "internal_link": "Residential Dijual di Batam",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/batam/residential`
            },
            {
                "internal_link": "Business Loft Dijual di Bogor",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/bogor/businessloft`
            },
            {
                "internal_link": "Kavling Lot Dijual di Bogor",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/bogor/kavling`
            },
            {
                "internal_link": "Residential Dijual di Bogor",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/bogor/residential`
            },
            {
                "internal_link": "Ruko Dijual di Bogor",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/bogor/shophouse`
            },
            {
                "internal_link": "Apartemen Dijual di BSD City",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/bsd-city/apartment`
            },
            {
                "internal_link": "Boutique SOHO Dijual di BSD City",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/bsd-city/boutiquesoho`
            },
            {
                "internal_link": "Business Loft Dijual di BSD City",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/bsd-city/businessloft`
            },
            {
                "internal_link": "Warehouse Dijual di BSD City",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/bsd-city/warehouse`
            },
            {
                "internal_link": "Kavling Lot Dijual di BSD City",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/bsd-city/kavling`
            },
            {
                "internal_link": "Kios Dijual di BSD City",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/bsd-city/kios`
            },
            {
                "internal_link": "Residential Dijual di BSD City",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/bsd-city/residential`
            },
            {
                "internal_link": "Ruko Dijual di BSD City",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/bsd-city/shophouse`
            },
            {
                "internal_link": "Kavling Lot Dijual di Cikarang",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/cikarang/kavling`
            },
            {
                "internal_link": "Residential Dijual di Cikarang",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/cikarang/residential`
            },
            {
                "internal_link": "Ruko Dijual di Cikarang",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/cikarang/shophouse`
            },
            {
                "internal_link": "Apartemen Dijual di Surabaya",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/surabaya/apartment`
            },
            {
                "internal_link": "Kavling Lot Dijual di Surabaya",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/surabaya/kavling`
            },
            {
                "internal_link": "Residential Dijual di Surabaya",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/surabaya/residential`
            },
            {
                "internal_link": "Ruko Dijual di Surabaya",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/surabaya/shophouse`
            },
            {
                "internal_link": "Apartemen Dijual di Tangerang",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/tangerang/apartment`
            },
            {
                "internal_link": "Kavling Lot Dijual di Tangerang",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/tangerang/kavling`
            },
            {
                "internal_link": "Residential Dijual di Tangerang",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/city/tangerang/residential`
            }
        ]
    },
    projectProperty: {
        name: "Proyek Properti Unggulan",
        links: [
            {
                "internal_link": "Properti Dijual di BSD City",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/bsd-city`
            },
            {
                "internal_link": "Properti Dijual di Wisata Bukit Mas",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/wisata-bukit-mas`
            },
            {
                "internal_link": "Properti Dijual di Hiera",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/hiera`
            },
            {
                "internal_link": "Properti Dijual di Grand City Balikpapan",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/grand-city-balikpapan`
            },
            {
                "internal_link": "Properti Dijual di Aerium",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/aerium`
            },
            {
                "internal_link": "Properti Dijual di Nuvasa Bay",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/nuvasa-bay`
            },
            {
                "internal_link": "Properti Dijual di Upper West",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/upper-west`
            },
            {
                "internal_link": "Properti Dijual di Taman Permata Buana",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/taman-permata-buana`
            },
            {
                "internal_link": "Properti Dijual di Kota Wisata",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/kota-wisata`
            },
            {
                "internal_link": "Properti Dijual di Kota Deltamas",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/kota-deltamas`
            },
            {
                "internal_link": "Properti Dijual di The Elements",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/the-elements`
            },
            {
                "internal_link": "Properti Dijual di Legenda Wisata",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/legenda-wisata`
            },
            {
                "internal_link": "Properti Dijual di Banjar Wijaya",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/banjar-wijaya`
            },
            {
                "internal_link": "Properti Dijual di Klaska Residence",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/klaska-residence`
            },
            {
                "internal_link": "Properti Dijual di Southgate",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/southgate`
            },
            {
                "internal_link": "Properti Dijual di Akasa Pure Living",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/project/akasa`
            }
        ]
    },
    ragamProperti: {
        name: "Ragam Properti",
        links: [
            {
                "internal_link": "Apartemen Dijual",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/apartment`
            },
            {
                "internal_link": "Kavling Lot Dijual",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/kavling`
            },
            {
                "internal_link": "Boutique SOHO Dijual",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/boutiquesoho`
            },
            {
                "internal_link": "Kios Dijual",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/kiosk`
            },
            {
                "internal_link": "Business Loft Dijual",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/businessloft`
            },
            {
                "internal_link": "Residential Dijual",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/residential`
            },
            {
                "internal_link": "Warehouse Dijual",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/warehouse`
            },
            {
                "internal_link": "Ruko Dijual",
                "link": `${process.env.NEXT_PUBLIC_MAINSITE_URL}/shophouse`
            }
        ]
    }
}
