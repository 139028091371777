import AutoSuggest from "../forms/AutoSuggest";

import { generateUrl } from "../../utils/Helper";
import classes from './Sidebar.module.css'

interface IProps {
    langCode: string
    lang: any
    show: boolean
    white?: boolean
    onClickClose: Function
    sidebarZIndex?: string
    isLoggedIn: boolean
    user_slug?: string
    logout: () => void
}

export default function Filter({ langCode, lang, white, show, onClickClose, sidebarZIndex, isLoggedIn, user_slug, logout }: IProps) {
    return (
        <div className={`${white ? `${sidebarZIndex || 'z-30'} z-30 bg-white text-black-48 px-4` : `${sidebarZIndex || 'z-10'}z-10 bg-main text-white pt-16 p-5`} fixed top-0 left-0 h-full w-full text-base overflow-y-auto pb-20 transform ${show ? '' : 'translate-x-full'} ease-in-out duration-300 block lg:hidden`}>
            <div className="flex flex-col pt-2">
                {
                    white ?
                        <div className="relative mb-4 flex flex-row justify-between">
                            <AutoSuggest />
                            <button
                                title="Option Sidebar"
                                id="button_option_sidebar"
                                className={`lg:hidden hamburger hamburger--slider is-active gray ${classes['p-0']}`}
                                onClick={() => onClickClose()}
                                type="button"
                            >
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                        : null
                }

                <div className="flex my-2">
                    <a href={generateUrl(langCode, "")}>
                        {lang.home}
                    </a>
                </div>

                <div className="flex my-2">
                    <a href={generateUrl(langCode, "/infiniteliving")}>
                        <img loading="lazy" width="20" height="20" src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/promotion.svg`} alt="Promotion Icon" className='inline mr-1' />
                        Promotion
                    </a>
                </div>

                <div className="flex my-2">
                    <a href={generateUrl(langCode, "/sinar-mas-pillars")}>
                        Sinar Mas Pillars
                    </a>
                </div>

                <div className="flex my-2">
                    <a href={generateUrl(langCode, "/article")}>
                        Articles
                    </a>
                </div>

                <hr className="my-2"></hr>

                <div className="flex my-2">
                    <a href={generateUrl(langCode, "/search")}>
                        {lang.browse_unit}
                    </a>
                </div>

                {/* <div className="flex my-2">
                    <a href={generateUrl(langCode, "/maps")}>
                        {lang.map}
                    </a>
                </div> */}

                <hr className="my-2"></hr>

                <div className="flex my-2">
                    <a href="https://sinarmasland.com/contact-us">
                        {lang.contact_us}
                    </a>
                </div>

                <div className="flex my-2">
                    <a href="https://sinarmasland.com/privacy-policy">
                        {lang.privacy_policy}
                    </a>
                </div>

                {
                    !isLoggedIn &&
                    <>
                        <hr className="my-2"></hr>

                        <div className="flex my-2">
                            <a href="/signin">
                                Login
                            </a>
                        </div>

                        <div className="flex my-2">
                            <a href="/signup">
                                Sign Up
                            </a>
                        </div>
                    </>
                }

                {
                    isLoggedIn ?
                        <>
                            <hr className="my-2"></hr>

                            <div className="flex my-2">
                                <a href={generateUrl(langCode, `/agent/${user_slug}`)} className="flex items-center">
                                    <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/icon-SellerPage.svg`} alt="heart" className="mr-2 w-5 h-5 object-contain" width="16" height="16" />
                                    My Page
                                </a>
                            </div>

                            <div className="flex my-2">
                                <a href={generateUrl(langCode, `/agent/dashboard`)} className="flex items-center">
                                    <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/icon-Dashboard.svg`} alt="heart" className="mr-2 w-5 h-5 object-contain" width="16" height="16" />
                                    Dashboard
                                </a>
                            </div>

                            <div className="flex my-2">
                                <a href={generateUrl(langCode, "/agent/enquiry-list")} className="flex items-center">
                                    <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/icon-enquiryList.svg`} alt="account" className="mr-2 w-5 h-5 object-contain" width="16" height="16" />
                                    Enquiry List
                                </a>
                            </div>

                            <div className="flex my-2">
                                <a href={generateUrl(langCode, "/agent/drive")} className="flex items-center">
                                    <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/drive.svg`} alt="account" className="mr-10px ml-0.5 w-4 h-4 object-contain" width="16" height="16" />
                                    eCatalog Drive
                                </a>
                            </div>

                            <div className="flex my-2">
                                <a href={generateUrl(langCode, "/agent/account")} className="flex items-center">
                                    <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/icon-navigation-user_24px.svg`} alt="account" className="mr-2 w-5 h-5 object-contain" width="16" height="16" />
                                    Account
                                </a>
                            </div>

                            <div className="flex my-2">
                                <div onClick={logout} className="flex items-center">
                                    <img src={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/static/images/svg/icon-navigation-login_24px.svg`} alt="signout" className="mr-2 w-5 h-5 object-contain" width="16" height="16" />
                                    Log Out
                                </div>
                            </div>
                        </>
                        : null
                }
            </div>
        </div>
    )


}